<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-item
        v-if="selectedView === '1'"
        slot="start"
        lines="none"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-item
        v-else
        slot="start"
        lines="none"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView = '1'"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-title class="text--white text-18 text--400">
        {{ $t('Create Room') }}
      </ion-title>
      <ion-button
        slot="end"
        fill="clear"
        debounce="500"
        :disabled="!roomName || !roomClass || saving"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="createRoom()"
      >
        <ion-text
          class="text-16"
          color="primary"
        >
          {{ $t('save') }}
        </ion-text>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="text-left create-scene">
    <div
      v-if="selectedView === '1'"
      class="ion-padding"
    >
      <div class="change-picture">
        <div v-if="!roomClass">
          <ion-item
            lines="none"
            color="transparent"
            detail="false"
            class="select-picture text--white"
            @click="selectedView = '2'"
          >
            <div class="change-image">
              <img src="../../assets/images/image.svg">
            </div>
          </ion-item>
          <div>
            <ion-item
              lines="none"
              color="transparent"
              class="select-picture text--white"
            >
              <ion-text class="select-picture-text text--gray text-16">
                {{ $t('selectImage') }}
              </ion-text>
            </ion-item>
          </div>
        </div>
        <div v-else>
          <ion-item
            color="transparent"
            lines="none"
            class="mt-10 rounded-corners h-100"
            :class="roomClass"
            @click="selectedView = '2'"
          />
        </div>
      </div>
      <form
        class="form--dark mt-30"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <ion-item
          lines="none"
        >
          <ion-label position="floating">
            {{ $t('Room Name') }}
          </ion-label>
          <ion-input
            v-model="roomName"
            class="capitalize"
            type="text"
            maxlength="11"
            enterkeyhint="done"
            required
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
      </form>
    </div>
    <div
      v-else-if="selectedView === '2'"
      class="ion-padding-start ion-padding-end"
    >
      <div
        v-for="item in backgrounds"
        :key="item"
      >
        <div
          style="height: 100px; border-radius: 10px; margin-top: 8px;"
          :class="item.bg"
          @click="roomClass = item.bg; selectedView = '1';"
        />
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  closeOutline,
  addCircleOutline,
  chevronBackOutline,
  addOutline
} from 'ionicons/icons'

import { Keyboard } from '@capacitor/keyboard'

export default ({
  components: {},
  props: {
    devices: { type: Array, default: () => [] },
    close: { type: Function, default: null }
  },
  data () {
    return {
      closeOutline,
      addCircleOutline,
      chevronBackOutline,
      addOutline,
      selectedView: '1',
      saving: false,
      roomName: '',
      roomClass: '',
      backgrounds: [
        { bg: 'living-room', class: 'livin' },
        { bg: 'living-room2', class: 'livin2' },
        { bg: 'living-room3', class: 'livin3' },
        { bg: 'living-room4', class: 'livin4' },
        { bg: 'living-room5', class: 'livin5' },
        { bg: 'chair', class: 'chair' },
        { bg: 'bathroom', class: 'bathroom' },
        { bg: 'bathroom3', class: 'bathroom3' },
        { bg: 'kitchen', class: 'kitch' },
        { bg: 'kitchen2', class: 'kitch2' },
        { bg: 'kitchen3', class: 'kitch3' },
        { bg: 'sleeping-room', class: 'sleep' },
        { bg: 'bedroom4', class: 'bedroom4' },
        { bg: 'kids-room', class: 'kids' }
      ]
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  methods: {
    dismissModal () {
      this.close()
    },
    createRoom () {
      this.saving = true
      this.$store.dispatch('addPropertyRoom', { roomName: this.roomName.replace(/(^\w|\s\w)/g, m => m.toUpperCase()), roomClass: this.roomClass }).then(() => {
        this.saving = false
        this.dismissModal()
      })
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/theme/_colors";
@import "~@/theme/_backgrounds";
.remove-device {
  .alert-wrapper {
    .alert-title {
      font-size: 16px !important;
      letter-spacing: 0.5px !important;
    }
  }
}
.room-details{
    padding:16px;
}
ion-text.select-device-title{
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: 1px;
text-transform: uppercase;
}
.change-image{
width: 59.54px;
    height: 36px;
    padding: 10px;
    border-radius: 60px;
    text-align: center;
    background: rgba(120, 120, 128, 0.32);
        margin: auto;
        img{
        margin-top: -3px;
        }
}
.select-picture i{
    background: #58586359;
    border-radius: 100px;
    margin: 0 auto;
    text-align: center;
    color:#FFD4A4;
    width:100px;
    padding:10px;
    font-size:26px;
}
.room-type-box{
  background:#2C3036;
  color:#fff;
  border-radius:4px;
  padding:12px 10px;
  font-size:13px;
  height: 44px;
  text-align:center;
  margin-top:10px;
}
.room-type-box.active{
  background:#2C3036;
  color:#FFD4A4;
  border:1px solid #FFD4A4;
}
.room-type{
  text-align:left;
  margin-top:10px;
  margin-bottom:10px;
}
.room-type ion-text{
font-weight:bold;
}
.room-type ion-col {
padding-left:0;
}
ion-segment {
margin-top:20px;
}
</style>
