<template>
  <ion-page>
    <div class="background--component" />
    <ion-header class="ion-no-border">
      <ion-toolbar color="transparent">
        <ion-item
          v-if="selectedView === '1'"
          slot="start"
          fill="clear"
          lines="none"
          color="transparent"
          class="close-modal-button"
          @click="$router.back()"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-item
          v-else
          slot="start"
          fill="clear"
          lines="none"
          color="transparent"
          class="close-modal-button"
          @click="selectedView = '1'"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-title
          class="text--white text-18"
        >
          {{ $t('rooms') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content
      color="transparent"
    >
      <div v-if="selectedView === '1'">
        <div
          v-for="room in rooms"
          :key="room"
        >
          <ion-card
            :class="room.roomClass"
            @click="openRoomModal(room.roomName, room.id, room)"
          >
            <ion-card-title class="text--white text-22">
              {{ room.roomName }}
            </ion-card-title>
          </ion-card>
        </div>
        <ion-fab
          slot="fixed"
          vertical="bottom"
          horizontal="end"
        >
          <ion-fab-button @click="addRoomModal">
            <ion-icon
              :icon="addOutline"
              class="text--black"
            />
          </ion-fab-button>
        </ion-fab>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { addOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'
import { modalController } from '@ionic/vue'
import roomModal from '../components/RoomModal.vue'
import addRoomModal from '../components/Rooms/AddRoomModal.vue'

export default defineComponent({
  data () {
    return {
      addOutline,
      selectedRoom: '',
      selectedView: '1',
      editingRoom: ''
    }
  },
  computed: {
    rooms () {
      return this.$store.state.propertyRooms
    }
  },
  methods: {
    dismissModal () {
      this.close()
    },
    async addRoomModal () {
      const modal = await modalController
        .create({
          component: addRoomModal,
          componentProps: {
            close: () => this.closeModal()
          }
        })
      return modal.present()
    },
    async openRoomModal (whichModal, roomId, roomObject) {
      const modal = await modalController
        .create({
          component: roomModal,
          cssClass: 'roomsDetails',
          componentProps: {
            roomId: roomId,
            roomObject: roomObject,
            modalTitle: whichModal,
            close: () => this.closeModal()
          }
        })
      return modal.present()
    },
    closeModal () {
      modalController.dismiss()
    }
  }
})
</script>

<style lang="scss" scoped>
ion-content {
  height: 100vh;
}
/* .room {
  color: white;
  font-size: 25px;
  padding: 15px;
  font-weight:bold;
  height: 100px;
  display: flex;
    margin-bottom: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: middle;
  position: relative;
  background-size: auto 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  position:relative;
  box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
}
.room-box{
  position:absolute;
  left:0;
  font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 26px;
  right:0;
  border-radius:10px;
  bottom:0;
  top:0;
      display: flex;
    align-items: center;
    justify-content: center;
  background: linear-gradient(164.34deg, rgba(189, 165, 137, 0.4) 34.13%, rgba(85, 76, 119, 0.332973) 65.17%, rgba(9, 21, 39, 0.4) 89.05%);
} */
/* .living-room {
    filter: drop-shadow(0px -10px 24px rgba(0, 0, 0, 0.25));
    background-image: linear-gradient(
      164.34deg,
      rgba(189, 165, 137, 0.4) 34.13%,
      rgba(85, 76, 119, 0.332973) 65.17%,
      rgba(9, 21, 39, 0.4) 89.05%
    ), url("../assets/images/livingroom.jpg");
    background-size: cover;
    background-position: center;
}
.kitchen{
    filter: drop-shadow(0px -10px 24px rgba(0, 0, 0, 0.25));
    background-image: linear-gradient(
      164.34deg,
      rgba(189, 165, 137, 0.4) 34.13%,
      rgba(85, 76, 119, 0.332973) 65.17%,
      rgba(9, 21, 39, 0.4) 89.05%
    ), url("../assets/images/dinigroom.jpg");
      background-size: cover;
    background-position: center;
}
.sleeping-room {
      filter: drop-shadow(0px -10px 24px rgba(0, 0, 0, 0.25));
    background-image: linear-gradient(
      164.34deg,
      rgba(189, 165, 137, 0.4) 34.13%,
      rgba(85, 76, 119, 0.332973) 65.17%,
      rgba(9, 21, 39, 0.4) 89.05%
    ), url("../assets/images/sleepingroom.jpg");
      background-size: cover;
    background-position: center;
}
.kids-room {
    filter: drop-shadow(0px -10px 24px rgba(0, 0, 0, 0.25));
    background-image: linear-gradient(
      164.34deg,
      rgba(189, 165, 137, 0.4) 34.13%,
      rgba(85, 76, 119, 0.332973) 65.17%,
      rgba(9, 21, 39, 0.4) 89.05%
    ), url("../assets/images/kidsroom.jpg");
    background-size: cover;
    background-position: center;
} */
.active {
  box-shadow: 0 0 5px #ddd
}
</style>
